import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import Comments from 'domains/comment/ui/Comments';
import { useUserSettingInfo, useUserTheme } from 'domains/user/model/selectors';
import OptimisationWidget from 'components/OptimisationWidget';
import LoadingOptimisationWidget from 'components/OptimisationWidget/Optimisation/loading';
import HistoryChangeWidget from 'components/HistoryChangeWidget';
import StatisticTypeTooltip from 'components/StatisticTypeTooltip';
import { useDispatchApp } from 'redux/rootSelectors';
import { fetchStatisticType } from 'domains/campaigns/reducer';
import { useDataCampaignsStatisticAndTranslation } from 'domains/campaigns/model/hooks/useDataCampaignsStatisticAndTranslation';
import { everyGroups } from 'utils/statics/everyGroups';
import { useStatisticType } from 'domains/campaigns/model/selectors';
import LoadingItem from 'domains/blackLists/ui/Item/loadingItem';
import { TID } from 'types/general';
import { useRouteMatch } from 'react-router';
// import Actions from 'components/GridCompaign/Actions';
import Empty from 'components/GridCompaign/Empty';
import Process from 'components/GridCompaign/Process';
import Created from 'components/GridCompaign/Created';
import { useIsGroup } from 'domains/user/hooks';
import { useCampaignGeneral } from '../hooks/useCampaignGeneral';
import HeaderCampaign from '../HeaderCampaign';
import css from './styles.module.scss';
import CardsList from '../../../components/UI/CardList';
import PreloaderFormList from '../../../components/Preloaders/FormList';
import PreloaderWidget from '../../../components/Preloaders/Widget';
import Statistics from './Statistics';
import ErrorReport from '../../../components/Errors/Reports';
import Subscription from '../../../components/Subscription';
import { useCampaignComments } from '../model/hooks/useCampaigComments';
import { useUserInfo } from '../../user/model/selectors';
import i18next from '../../../i18n';
import CollapseWrapper from '../../../components/CollapseWrapper';
import EditCampaign from '../ui/Main/EditCampaign';
import Translations from '../../../components/Translation/TranslationWidget';
import { useCampaingTranslationInfo } from '../model/hooks/useCampaingTranslationInfo';
import Limits from './Limits';
import { useCampaignGroupWidgetData } from '../model/hooks/useCampaignGroupWidgetData';
import { fetchCreateCampaignGrid, fetchPersonalData } from '../model/actions';

const Main: FC = () => {
  const { t } = useTranslation();
  const { data: user } = useUserInfo();
  const dispatch = useDispatchApp();
  const { params } = useRouteMatch<TID>();
  const { data: userSettings } = useUserSettingInfo();
  const { isOrdOwner, isOwner } = useIsGroup();
  const [isCreateGridCampaignLoading, setIsCreateGridCampaignLoading] = useState<boolean>(false);

  const {
    campaign,
    pastCampaign,
    isLoading,
    error,
    handlerFetchStatistics,
    handlerFetchTranslation,
  } = useCampaignGeneral();
  const { data: statisticType, isLoading: isLoadingStatisticType } =
    useStatisticType();

  const {
    comments,
    isLoading: isLoadingComment,
    LTU,
    handleAddComment,
    handleEditComment,
    handleDeleteComment,
    handleFetchComments,
  } = useCampaignComments();

  const { getValueFromSettingForKey } = useUserTheme();

  const {
    translationInfo,
    isLoading: isLoadingTransition,
    time,
    isError,
  } = useCampaingTranslationInfo();

  const {
    groupInfo,
    updatedCampaignHistory,
    getCampaignHistory,
    isLoading: isLoadingHistory,
  } = useCampaignGroupWidgetData({ campaign, user });

  const statAndTranlationData = useDataCampaignsStatisticAndTranslation();

  const isCreateCampaignWidgetShow = () => {
    if (
      campaign?.campaign_grid &&
      isOrdOwner &&
      isOwner
    )
      return true;
    return false;
  };

  const showSubscription = () =>
    campaign &&
    !!userSettings?.find((s) => s.field === 'notification' && s.value === true);

  const isStatisticNullable = (): boolean => {
    if (campaign) {
      const statistics = statAndTranlationData.statistics?.[campaign?.xxhash];
      return statistics
        ? Object.values(statistics).every((stat) => stat === 0)
        : false;
    }
    return false;
  };

  const getCurrentStatisticType = () => {
    if (statisticType) {
      return statisticType[0].statistic_type;
    }
    return null;
  };

  useEffect(() => {
    const isReturnToMain = localStorage.getItem('toMain') !== null;
    if (isReturnToMain) {
      dispatch(fetchPersonalData(params.id));
    }
    dispatch(fetchStatisticType({ xxhash_list: [params.id] }));
  }, [dispatch, params.id]);

  useEffect(
    () => () => {
      localStorage.removeItem('localHistoryParams');
      localStorage.removeItem('changeType');
      localStorage.removeItem('toMain');
    },
    [],
  );

  return (
    <>
      <Helmet>
        <title>
          {t(`campaigns_page.campaign_settings.black_menu.Main_settings`)}
        </title>
      </Helmet>
      {error.isError && <ErrorReport error={error} />}
      {!error.isError && (
        <>
          <HeaderCampaign campaign={campaign} isLoading={isLoading} />
          <div>
            <div className={cn(css.container, 'container')}>
              <CardsList className={css.cards}>
                {(isLoading || campaign === null) && <PreloaderFormList />}
                {!isLoading && campaign && (
                  <EditCampaign
                    campaign={campaign}
                    pastCampaign={pastCampaign.data}
                  />
                )}
              </CardsList>
              <CardsList>
                <div>
                  {campaign && showSubscription() && (
                    <Subscription
                      entity_id={campaign.xxhash}
                      entity_type="Campaign"
                    />
                  )}
                  {campaign ? (
                    <OptimisationWidget campaign={campaign} />
                  ) : (
                    <LoadingOptimisationWidget />
                  )}
                  {getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                    !getValueFromSettingForKey('ShowBroadcastStatus') && (
                      <CollapseWrapper
                        title={i18next.t('translations.title_widget')}
                        nameWrapper="CAMPAIGN_TRANSLATIONS"
                        fetcher={handlerFetchTranslation}
                        isLoading={isLoading}
                        classname={css.translation}
                      >
                        {!isLoading ? (
                          <Translations
                            onClickHandler={handlerFetchTranslation}
                            isLoading={isLoadingTransition}
                            translationInfo={translationInfo}
                            time={time}
                            isError={isError}
                            type="CAMPAIGN"
                          />
                        ) : (
                          <PreloaderWidget />
                        )}
                      </CollapseWrapper>
                    )}
                  <CollapseWrapper
                    title={
                      <div className={css.statisticsTitle}>
                        <span>
                          {i18next.t(`campaigns_page.stat_chart.tit`)}
                        </span>
                        {everyGroups(user, 'test') &&
                          (!isLoading && !isLoadingStatisticType ? (
                            <StatisticTypeTooltip
                              statistic_type={getCurrentStatisticType()}
                              isStatisticIsNull={isStatisticNullable()}
                            />
                          ) : (
                            <LoadingItem />
                          ))}
                      </div>
                    }
                    nameWrapper="CAMPAIGN_STATISTICS"
                    fetcher={handlerFetchStatistics}
                    isLoading={isLoading}
                  >
                    {!isLoading ? (
                      <Statistics
                        handlerFetchStatistics={handlerFetchStatistics}
                      />
                    ) : (
                      <PreloaderWidget />
                    )}
                  </CollapseWrapper>

                  {groupInfo &&
                    !getValueFromSettingForKey('groups_limits') &&
                    getValueFromSettingForKey('groups_limits') !== null && (
                      <CollapseWrapper
                        title={i18next.t('clients_page.limit_groups.title2')}
                        nameWrapper="CAMPAIGN_GROUP_LIMITS"
                      >
                        <Limits groupInfo={groupInfo} />
                      </CollapseWrapper>
                    )}

                  <CollapseWrapper
                    title={i18next.t(`campaigns_page.campaigns_comments.title`)}
                    nameWrapper="CAMPAIGN_COMMENT"
                    fetcher={handleFetchComments}
                  >
                    {!isLoadingComment && comments && user ? (
                      <Comments
                        email={user.email}
                        LTU={LTU}
                        isLoading={isLoadingComment}
                        comments={comments}
                        handlerAddComment={handleAddComment}
                        handleEditComment={handleEditComment}
                        handleDeleteComment={handleDeleteComment}
                        handleFetchComments={handleFetchComments}
                      />
                    ) : (
                      <PreloaderWidget />
                    )}
                  </CollapseWrapper>
                  {user?.group.includes('owner') && (
                    <CollapseWrapper
                      title={i18next.t(`history_changes_page.title`)}
                      nameWrapper="CAMPAIGN_HISTORY"
                    >
                      {!isLoadingComment &&
                      !isLoadingHistory &&
                      comments &&
                      user &&
                      updatedCampaignHistory ? (
                        <HistoryChangeWidget
                          list={updatedCampaignHistory}
                          getHistory={getCampaignHistory}
                        />
                      ) : (
                        <PreloaderWidget />
                      )}
                    </CollapseWrapper>
                  )}
                  {isCreateCampaignWidgetShow() && (
                    <CollapseWrapper
                      title={t(`ord.contracts.campagn_grid`)}
                      nameWrapper="CAMPAIDNS_GRID"
                      isLoading={isCreateGridCampaignLoading}
                    >
                      {/* Временно убрали кнопку обновления статуса */}
                      {/* {true && (
                        <Actions
                          onClickAction={() => {
                            console.log('test update');
                          }}
                        />
                      )} */}
                      {campaign?.campaign_grid?.status === 'NOT_CREATED' && (
                        <Empty
                          handleCreateCampaign={() => {
                            dispatch(fetchCreateCampaignGrid({
                              xxhash_list: campaign.xxhash,
                              ord_name: campaign.ord_name || '',
                              contract_uuid: campaign.contract_uuid || '',
                              date_start: campaign.date_start || '',
                              date_end: campaign.date_end || '',
                              kktu_codes: campaign.kktu_codes || '',
                              callback: {
                                setIsCreateGridCampaignLoading
                              }
                            }))
                          }}
                          text={t(`ord.contracts.campagn_not_created2`)}
                          isDisable={!(!campaign.ord_name && !campaign.contract_uuid)}
                        />
                      )}
                      {isCreateGridCampaignLoading && <Process creatingState={false} />}
                      {campaign && campaign.campaign_grid &&
                        campaign?.campaign_grid?.status !== 'NOT_CREATED' && (
                          <Created data={campaign.campaign_grid} />
                        )}
                    </CollapseWrapper>
                  )}
                </div>
              </CardsList>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Main;
