import { FormikProps, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { useCampainExperimentalInfo } from 'domains/campaign/model/hooks/useCampaingExperimentalInfo';
import { createConfiValues } from 'utils/createConfigValues';
import { useOffersList } from 'domains/offers/model/selectors';
import { getOffersList } from 'domains/offers/model/actions';
import { useContractsList } from 'domains/contracts/model/selectors';
import { getContractsList } from 'domains/contracts/model/actions';
import { TCampaignForm, TExperimentalInfoField } from 'domains/campaign/types';
import { useClientInfo } from 'domains/client/model/selectors';
import { fetchClientInfoById } from 'domains/client/reduser';
import { useUserInfo } from 'domains/user/model/selectors';
import { someGroups } from 'utils/statics/everyGroups';
import { useDispatchApp } from '../../../../../../redux/rootSelectors';
import { useDelLastIdCampaigns } from '../../../../../../utils/useLastIdHelpers/useLastIdCampaign';
import { useCampaignsInfo } from '../../../../../campaigns/model/selectors';
import { useCurrency, useProjectInfo } from '../../../../../project/hooks';
import { useIsGroup } from '../../../../../user/hooks';
import { useCampaignAdvancedOptimizationInfo } from '../../../../model/selectors';
import { SelectField } from '../../../../../../types/general';
import { useClientsInfo } from '../../../../../clients/model/selectors';
import { useSearchBreadcrumbsInfo } from '../../../../../search/model/selectors';
import { fetchAdvancedOptimizationLists, fetchCampaignExperimental, fetchOptimizationList } from '../../../../model/actions';
import { fetchClients } from '../../../../../clients/reducer';
import {
  validateFormCampaign,
  validateSchemeCampaign,
} from '../../../../model/shared/validateForm';
import { objectOptimizationWithFormik } from '../../../../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { createCampaignAction } from '../../../../../campaigns/reducer';
import { contractsOptionsFunc, offersOptionsFunc } from '../../utils';

type TFUseCreateCampaignForm = () => {
  formik: FormikProps<TCampaignForm>;
  onKeyDown: (v: React.KeyboardEvent) => void;
  goBack: (v?: string) => void;
  isLoading: ReturnType<typeof useCampaignsInfo>['isLoading'];
  currency: ReturnType<typeof useCurrency>['currency'];
  isRawStat: ReturnType<typeof useIsGroup>['isRawStat'];
  isTest: ReturnType<typeof useIsGroup>['isTest'];
  advancedList: ReturnType<typeof useCampaignAdvancedOptimizationInfo>['data'];
  handlerUpdateTags: (value: string[]) => void;
  tags: string[];
  clients: SelectField[];
  clientDisabled: boolean;
  experimentalInfo: TExperimentalInfoField[] | null;
  offersOptions?: SelectField[];
  contractsOptions?: SelectField[];
};

export const useCreateCampaignForm: TFUseCreateCampaignForm = () => {
  const dispatch = useDispatchApp();
  const removeLastID = useDelLastIdCampaigns();
  const location = useLocation();
  const history = useHistory();
  const { currency } = useCurrency();
  const { isRawStat, isTest } = useIsGroup();
  const { data: advancedList } = useCampaignAdvancedOptimizationInfo();
  const { data: clientsTemp, LTU: LTUClients } = useClientsInfo();
  const { data: breadcrumbs, LTU: LTUBreadcrumbs } = useSearchBreadcrumbsInfo();
  const { data: contractsList } = useContractsList();
  const { data: projectData } = useProjectInfo();
  const { data: user } = useUserInfo();
  const { data: partner } = useClientInfo();

  const { isLoading } = useCampaignsInfo();
  const currentClient = new URL(window.location.href).searchParams.get('client_id');

  const { experimentalInfo } = useCampainExperimentalInfo();

  const configValues = useMemo(
    () =>
      createConfiValues(experimentalInfo, {}, [
        'coef_name',
      ]),
    [experimentalInfo],
  );

  const { data: offersList } = useOffersList();

  const offersOptions = offersOptionsFunc(offersList);
  const contractsOptions = contractsOptionsFunc(contractsList);

  const initialValues: TCampaignForm = {
    offer_id: null,
    pay_price: null,
    pay_optimization_field_id: null,
    ord_name: '',
    partner_xxhash: '',
    advertiser_name: '',
    title: i18next.t('campaigns_page.create_campaign.newcamp_tit'),
    contract_uuid: null,
    status: 'STOPPED',
    limits: {
      budget: {
        total: 0,
        day: 0,
        hour: 0,
      },
      budget_raw: {
        total: 0,
        day: 0,
        hour: 0,
      },
      budget_per_user: {
        total: 0,
        day: 0,
        hour: 0,
      },
      show: {
        total: 0,
        day: 0,
        hour: 0,
      },
      show_per_user: {
        total: 0,
        day: 0,
        hour: 0,
      },
      click: {
        total: 0,
        day: 0,
        hour: 0,
      },
      click_per_user: {
        total: 0,
        day: 0,
        hour: 0,
      },
      use_adriver_frequency_cap: false,
    },
    pay_type: 'cpm',
    config_values: configValues,
    container: {
      cid: '',
      save_strk_to_cookie: 0,
    },
    date_start: new Date(),
    date_end: moment(new Date()).add(14, 'day').toDate(),
    cpa_optimizations: false,
    advanced_optimizations: {},
    kktu_codes: ''
  };

  const clients = useMemo<SelectField[]>(() => {
    if (!clientsTemp) {
      return [];
    }
    return clientsTemp
      .filter(
        (client) => client.status === 'STOPPED' || client.status === 'LAUNCHED',
      )
      .map((client) => ({ label: client.title, value: client.id }));
  }, [LTUClients]);

  const [tags, setTags] = useState<string[]>([]);

  const handlerUpdateTags = (data: string[]) => {
    setTags(() => data);
  };

  const formik = useFormik<TCampaignForm>({
    initialValues,
    validate: validateFormCampaign,
    validationSchema: validateSchemeCampaign(user?.group, false),
    onSubmit: (values) => {
      const paramData = objectOptimizationWithFormik<TCampaignForm>(
        initialValues,
        values,
      );
      const params = {
        ...paramData,
        ord_name: values.ord_name,
        title: values.title,
        limits: {
          ...values.limits,
        },
        date_start: moment(values.date_start).format('YYYY-MM-DD'),
        date_end: moment(values.date_end).format('YYYY-MM-DD'),
        callbacks: { goBack },
        tags,
        cpa_optimizations: values.cpa_optimizations,
        advanced_optimizations: Object.values(
          values.advanced_optimizations,
        ).map((v) => +v),
        kktu_codes: values.kktu_codes
      };

      if (!partner?.ord_registration_creatives) {
        delete params.kktu_codes;
      }

      if (!params.ord_name) {
        delete params.ord_name;
      }

      dispatch(createCampaignAction(params));
    },
  });
  
  const onKeyDown: ReturnType<TFUseCreateCampaignForm>['onKeyDown'] = (
    keyboardEvent,
  ) => {
    const { charCode, keyCode } = keyboardEvent;
    if ((charCode || keyCode) === 13) {
      keyboardEvent.preventDefault();
    }
  };

  const goBack: ReturnType<TFUseCreateCampaignForm>['goBack'] = (result) => {
    removeLastID();
    if (typeof result === 'string' && result) {
      let url;
      if (location.search.includes('client_id')) {
        url = `/main/campaigns${location.search}&highlights=${result}&status=STOPPED`;
      } else {
        url = `/main/campaigns?highlights=${result}`;
      }
      return history.push(url || '/main/campaigns');
    }
    return history.push(
      location.search ? `/main/campaigns${location.search}` : '/main/campaigns',
    );
  };

  const clientDisabled = useMemo<boolean>(() => {
    if (!breadcrumbs) {
      return true;
    }
    return 'Client' in breadcrumbs;
  }, [LTUBreadcrumbs]);

  useEffect(() => {
    dispatch(fetchCampaignExperimental());
  }, [isLoading]);

  useEffect(() => {
    if (breadcrumbs) {
      if ('Client' in breadcrumbs) {
        formik.setValues({
          ...formik.values,
          partner_xxhash: breadcrumbs.Client.xxhash,
        });
      }
    }
  }, [LTUBreadcrumbs]);

  useEffect(() => {
    if (currentClient) {
      dispatch(
        getOffersList({ partner_xxhash_list: [currentClient] }),
      );
    }
  }, [currentClient]);

  useEffect(() => {
    if (
      user?.partner.type !== 'CLIENT' &&
      someGroups(user, 'owner', 'self_user') &&
      projectData?.ord_registration &&
      partner?.ord_registration_creatives
    ) {
      dispatch(getContractsList({ filter: { status: ['ACTIVE'] } }));
    }
  }, [
    user?.partner.type,
    projectData?.ord_registration,
    partner?.ord_registration_creatives,
  ]);

  useEffect(() => {
    dispatch(fetchAdvancedOptimizationLists());
    dispatch(fetchClients({}));
  }, []);

  useEffect(() => {
    if (breadcrumbs?.Client) {
      dispatch(fetchClientInfoById(breadcrumbs?.Client.xxhash));
    }
  }, [breadcrumbs]);

  useEffect(() => {
    if (formik.values.pay_type !== 'cpa') return
    dispatch(fetchOptimizationList());
  }, [formik.values.pay_type]);

  return {
    tags,
    handlerUpdateTags,
    formik,
    onKeyDown,
    goBack,
    isLoading,
    currency,
    isRawStat,
    isTest,
    advancedList,
    clients,
    clientDisabled,
    offersOptions,
    experimentalInfo,
    contractsOptions,
  };
};
