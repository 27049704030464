import { createAction } from '@reduxjs/toolkit';
import { ParamsCampaignsStatistics } from 'domains/campaigns/types';
import {
  TAddComment,
  TCommentID,
  TEditComment,
} from 'domains/comment/model/types';
import { TagsParam, TCallback, THASH } from '../../../types/general';
import {
  PayloadExperimental,
  PCampaignReport,
  PSetEditPersonalCampaign,
  PSetPreTargetingWorker,
  TCreateCampaignGrid,
} from '../types';

export const fetchPersonalStatistics = createAction<ParamsCampaignsStatistics>(
  'campaign/fetchPersonalStatistics',
);

export const setEditPersonalCampaign = createAction<PSetEditPersonalCampaign>(
  'campaign/setEditPersonalCampaign',
);

export const fetchCampaignPreTargeting = createAction<THASH>(
  'campaign/fetchCampaignPreTargeting',
);

export const setPreTargetingRequest = createAction<PSetPreTargetingWorker>(
  'campaign/setPreTargetingRequest',
);
export const fetchTagsGetCompany = createAction<string>(
  'campaign/fetchTagsGetCompany',
);

export const fetchTagsGetPartner = createAction<string>(
  'campaign/fetchTagsGetPartner',
);

export const fetchListSRMethods = createAction('campaign/fetchListSRMethods');

export const setSRMethodRequest = createAction<{
  SRMethod: string;
  id: string;
  title: string;
}>('campaign/setSRMethod');

export const fetchDownloadReport = createAction<PCampaignReport>(
  'campaign/fetchDownloadReport',
);

export const fetchReportType = createAction<PCampaignReport>(
  'campaign/fetchReportType',
);

export const fetchExternalModerationRequest = createAction<THASH>(
  'campaign/fetchExternalModerationRequest',
);

export const fetchPersonalData = createAction<string>(
  '@@campaign/fetchPersonalData',
);
export const fetchPDataByTarget = createAction<{ id: string; }>(
  '@@campaign/fetchPDataByTarget',
);

export const fetchAdvancedOptimizationLists = createAction(
  'campaign/fetchAdvancedOptimizationLists',
);

export const fetchCampaignComments = createAction<THASH & { clear?: boolean }>(
  'campaign/fetchCampaignComments',
);

export const addCampaignComment = createAction<TAddComment>(
  'campaign/addCampaignComment',
);

export const editCampaignComment = createAction<
  TEditComment & THASH & TCallback
>('campaign/editCampaignComment');

export const deleteCampaignComment = createAction<
  TCommentID & THASH & TCallback
>('campaign/deleteCampaignComment');

export const delayUpdateComment = createAction<
  TCommentID & { type: 'edited' | 'deleted' }
>('campaign/delayUpdateComment');

export const updateTagCampaign = createAction<TagsParam>(
  'campaign/updateTagCampaign',
);

export const revertCampaignTags = createAction<THASH>(
  'campaign/revertCampaignTags',
);

export const fetchCampaignTranslation = createAction<THASH>(
  'campaign/fetchCampaignTranslation',
);

export const fetchCampaignExperimental = createAction(
  'campaign/fetchCampaignExperimental',
);

export const setCampaignExperimentalAction = createAction<PayloadExperimental>(
  'campaign/setCampaignExperimental',
);

export const fetchOptimizationList = createAction(
  'campaign/fetchOptimizationList',
);

export const linkCampaignToOffer = createAction<{ id: number, campaigns_xxhash: string[] }>(
  'campaign/linkCampaignToOffer',
);

export const unlinkCampaignToOffer = createAction<{ campaigns_xxhash: string[] }>(
  'campaign/unlinkCampaignToOffer',
);

export const fetchCreateCampaignGrid = createAction<TCreateCampaignGrid>(
  'campaign/fetchCreateCampaignGrid',
);

